<template>
  <div id="payroll-page">
    <nav>
      <el-button
        v-if="currentView === 'draft_pay_periods'"
        type="plain"
        size="mini"
        class="right margin-top"
        :class="{ active: currentView === 'pay_cycles' }"
        @click.prevent="setView('pay_cycles'), reload_pay_cycles()"
      >
        New Pay Cycle
      </el-button>
      &nbsp;
      <el-button
        v-show="currentView === 'pay_cycles' || currentView === 'transactions'"
        type="primary"
        size="mini"
        class="right margin-top"
        :class="{ active: currentView === 'draft_pay_periods' }"
        @click.prevent="setView('draft_pay_periods'), reload_transactions()"
      >
        Draft Pay Run
      </el-button>
    </nav>
    <div
      v-show="currentView === 'transactions' || currentView === 'draft_pay_periods'"
      @click.prevent="setView('transactions')"
    >
      <pay-run-transactions
        ref="payRunTransactions"
        :key="transactions_key"
        :class="{ active: currentView === 'transactions' }"
        @hook:mounted="set_default_input"
      />
    </div>
    <div v-show="currentView === 'draft_pay_periods'">
      <draft-pay-periods
        ref="draftPayPeriod"
        @hook:mounted="set_default_input"
        @reload_draft_pay_transactions="reload_draft_pay_transactions"
      />
    </div>
    <div v-show="currentView === 'pay_cycles'">
      <pay-cycles
        :key="pay_cycles_key"
        @hook:mounted="set_default_input"
        @reload_employees="reload_employees"
      />
    </div>
  </div>
</template>

<script>
import PayCycles from '../payroll/PayCycles.vue';
import DraftPayPeriods from './DraftPayPeriods.vue';
import PayRunTransactions from './PayRunTransactions.vue';
import {set_default_input} from '../../utilities/DefaultInput';
export default {
  name: 'PayrollPage',
  components: {DraftPayPeriods, PayCycles, PayRunTransactions },
  props: {},
  data() {
    return {
      currentView: 'draft_pay_periods',
      transactions_key: 0,
      pay_cycles_key: 0
    };
  },
  methods: {
    set_default_input,
    setView(view) {
      this.currentView = view;
    },
    reload_draft_pay_transactions(){
      // @event when pay employees
      this.$refs.payRunTransactions.pay_run_transaction_monthly(false);
    },
    reload_employees(){
      // @event when on Pay cycles remove or add a new employee
      this.$refs.draftPayPeriod.get_pay_cycle_dates();
      // this.$refs.draftPayPeriod.calculate_employees_pay();
    },
    reload_transactions() {
      this.transactions_key += 1;
    },
    reload_pay_cycles() {
      this.pay_cycles_key += 1;
    },
  }
};
</script>
