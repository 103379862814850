<template>
  <div>
    <el-autocomplete
      size="mini"
      v-show="
        selected_object != null &&
          (this.selected_object['nodeName'] || '') != ''
      "
      class="inline-input"
      v-model="new_attribute"
      :fetch-suggestions="querySearch"
      value-key="name"
      placeholder="Please Input"
      :trigger-on-focus="false"
      @select="handleSelect"
    >
      <el-button
        slot="append"
        icon="el-icon-plus"
        @click="add_attribute"
        :disabled="new_attribute == '' ? true : false"
      ></el-button>
    </el-autocomplete>
    <ObjectAttribute
      v-for="attribute in object_attributes"
      :load_attributes="load_attributes"
      :attribute="attribute"
      :key="attribute.name"
      :aspect_ratio="aspect_ratio"
      :add_editor_action="add_editor_action"
    ></ObjectAttribute>
    <el-form
      v-show="
        selected_object != null && this.selected_object['nodeName'] == 'text'
      "
      label-width="70px"
    >
      <el-form-item label="bground">
        <el-select v-model="text_bg_color" filterable placeholder="Select">
          <el-option
            v-for="item in text_colors"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-form label-width="70px" v-show="selected_object == null ? false : true">
      <el-form-item label="Rotation">
        <el-slider
          input-size="mini"
          v-model="rotation_deg"
          :min="0"
          :max="360"
          :step="1"
          show-tooltip
          show-input
        ></el-slider>
      </el-form-item>
    </el-form>
    <el-checkbox
      v-if="selected_object == null ? false : true"
      v-model="aspect_ratio"
      label="Maintain ratio"
      size="medium"
    ></el-checkbox>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px } from "store/modules/editor";
import attributes_template from "./../templates/attributes_template.json";
import ObjectAttribute from "./ObjectAttribute.vue";

export default {
  name: "AttributesPanel",
  store: editor_store,
  components: { ObjectAttribute },
  data() {
    return {
      attributes_template: attributes_template,
      object_attributes: [],
      new_attribute: "",
      aspect_ratio: true,
      rotation_deg: 0,
      text_bg_color: "none",
    };
  },
  props: ["update_boundary", "add_editor_action"],
  mounted() {
    this.load_attributes();
  },
  methods: {
    ...mapActions(["set_file_changed"]),
    add_attribute() {
      //later add to style or to attributes
      if (
        this.selected_object.attributes[this.new_attribute.trim()] != undefined
      ) {
        this.$message({
          type: "info",
          message: "Attribute exists already!",
        });
        return;
      }
      let props = this.get_attribute_prop(this.new_attribute.trim());
      this.object_attributes.push({
        name: this.new_attribute.trim(),
        value: "",
        group: props.group,
        prop: props,
      });
      if (props.group == "style")
        this.selected_object.style[this.new_attribute.trim()] = "";
      else this.selected_object.setAttribute(this.new_attribute.trim(), "");
      this.new_attribute = "";
    },
    querySearch(queryString, cb) {
      var links = this.attributes_template;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    get_attribute_prop(attribute_name) {
      let props = {};

      let result = this.attributes_template.filter(
        (c) => c.name == attribute_name
      );
      if (result.length > 0) props = result[0];
      else props = { group: "attribute" };
      return props;
      //   let props = {};
      //   switch (attribute_name) {
      //     case "font-size":
      //       props = {
      //         type: "autocomplete",
      //         options:
      //           "medium|xx-small|x-small|small|large|x-large|xx-large|smaller|larger|length|initial|inherit"
      //       };
      //   }
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll("[data-prop]")).forEach(function(
        e,
        i
      ) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
        // e.addEventListener("change", _this.update_attribute);
        //e.style.width = "90% ";
      });
    },
    // update_attribute(e) {
    //   let attr = e.target.attributes["data-prop"].value;
    //   let group = e.target.attributes["data-group"].value;
    //   let value = e.target.value;
    //   switch (group) {
    //     case "style":
    //       d3.select(this.selected_object).style(attr, value);
    //       break;
    //     case "text":
    //       d3.select(this.selected_object).html(value);
    //       d3.selectAll(".selected_object_frame rect").attr(
    //         "width",
    //         this.selected_object.getBBox().width
    //       );
    //       d3.selectAll(".selected_object_frame rect").attr(
    //         "height",
    //         this.selected_object.getBBox().height
    //       );
    //       break;
    //     //   case 'attributes':
    //     //   this.selected_object.attr(attr, value);
    //     default:
    //       d3.select(this.selected_object).attr(attr, value);
    //   }
    // },
    load_attributes() {
      this.object_attributes = [];
      if (
        this.selected_object != undefined &&
        this.selected_object.attributes != undefined
      ) {
        var i = 0;
        for (i = 0; i < this.selected_object.attributes.length; i++) {
          let obj = this.selected_object.attributes[i];
          if(obj.name == "xlink:href" || obj.name == "href" || obj.name == "d")
          continue;
          if (obj.name.trim() == "style") {
            let style_attributes = obj.value.trim().split(";");
            var a = 0;
            for (a = 0; a < style_attributes.length; a++) {
              let style_attribute = style_attributes[a].trim();
              if (style_attribute == "") continue;
              let name = style_attribute.split(":")[0].trim();

              let value = style_attribute.split(":")[1].trim();

              //el-color-picker , el-autocomplete , el-slider
              this.object_attributes.push({
                name: name,
                value: value,
                group: "style",
                prop: this.get_attribute_prop(name),
              });
            }
          } else {
            this.object_attributes.push({
              name: obj.name,
              value: isNaN(obj.value) ? obj.value : parseFloat(obj.value),
              group: obj.name,
              prop: this.get_attribute_prop(obj.name),
            });
          }
        }
        if (this.selected_object.nodeName == "text") {
          this.object_attributes.push({
            name: "text",
            value: d3.select(this.selected_object).text(),
            group: "text",
            prop: {},
          });
        }
      }

      //this.set_inputs_to_default();
      //   var i = 0;
      //   for (i = 0; i < this.object_attributes.length; i++) {
      //     let prop = this.object_attributes[i];
      //     let prop_name = "object_attributes." + prop.name;
      //     if (this._watchers.filter(c => c.expression == prop_name).length > 0)
      //       continue;

      //     this.$watch(
      //       function() {
      //         return prop_name;
      //       },
      //       function(newValue, oldValue) {
      //         alert(
      //           "First name changed from " + oldValue + " to " + newValue + "!"
      //         );
      //       }
      //     );
      //   }
    },
    rotate_object() {
      let deg = this.rotation_deg;
      let _this = this;
      let trans = _this.selected_object.getAttribute("transform");
      let idxTrans = -1;
      let rotated = false;
      let rotate_trans;
      if (trans != undefined) {
        Array.from(_this.selected_object.transform.baseVal).forEach(
          (el, idx) => {
            if (el.type == 4) {
              rotate_trans = el;
              idxTrans = idx;
            }
          }
        );

        if (deg == 0) {
          if (trans != undefined)
            _this.selected_object.transform.baseVal.removeItem(idxTrans);
        } else if (rotate_trans != undefined) {
          rotate_trans.setRotate(
            deg,
            _this.selected_object.getBBox().x +
              _this.selected_object.getBBox().width / 2,
            _this.selected_object.getBBox().y +
              _this.selected_object.getBBox().height / 2
          );
          rotated = true;
        }
      }

      if ((trans == undefined || rotated == false) && deg != 0) {
        let rt = _this.selected_object.ownerSVGElement.createSVGTransform();
        rt.setRotate(
          deg,
          _this.selected_object.getBBox().x +
            _this.selected_object.getBBox().width / 2,
          _this.selected_object.getBBox().y +
            _this.selected_object.getBBox().height / 2
        );
        _this.selected_object.transform.baseVal.appendItem(rt);
      }
      _this.update_boundary(_this.selected_object);
      return;
    },
    getTransformation(transform) {
      // Create a dummy g for calculation purposes only. This will never
      // be appended to the DOM and will be discarded once this function
      // returns.
      var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

      // Set the transform attribute to the provided string value.
      g.setAttributeNS(null, "transform", transform);

      // consolidate the SVGTransformList containing all transformations
      // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
      // its SVGMatrix.
      var matrix = g.transform.baseVal.consolidate().matrix;

      // Below calculations are taken and adapted from the private function
      // transform/decompose.js of D3's module d3-interpolate.
      var { a, b, c, d, e, f } = matrix; // ES6, if this doesn't work, use below assignment
      // var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
      var scaleX, scaleY, skewX;
      if ((scaleX = Math.sqrt(a * a + b * b))) (a /= scaleX), (b /= scaleX);
      if ((skewX = a * c + b * d)) (c -= a * skewX), (d -= b * skewX);
      if ((scaleY = Math.sqrt(c * c + d * d)))
        (c /= scaleY), (d /= scaleY), (skewX /= scaleY);
      if (a * d < b * c)
        (a = -a), (b = -b), (skewX = -skewX), (scaleX = -scaleX);
      return {
        translateX: e,
        translateY: f,
        rotate: (Math.atan2(b, a) * 180) / Math.PI,
        skewX: (Math.atan(skewX) * 180) / Math.PI,
        scaleX: scaleX,
        scaleY: scaleY,
      };
    },
    load_transformation() {
      let trans = this.selected_object.getAttribute("transform");
      console.log("trans", trans);
      let rotate = 0;
      if ((trans != undefined) & (trans != ""))
        rotate = this.getTransformation(trans).rotate;
      console.log("rotate", rotate);
      this.rotate_object(rotate);
      this.set_file_changed(true);
    },
    update_text_bg(newVal) {
      if (newVal != "none") {
        let filter = d3.select(`#${newVal}`).node();
        if (filter == undefined) {
          let text_bg = d3
            .selectAll("#proposal_workspace .layers")
            .append("defs")
            .append("filter")
            .attr("id", newVal)
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 1)
            .attr("height", 1);

          text_bg
            .append("feFlood")
            .attr("flood-color", newVal.replace(/text_bg_/gi, ""));

          text_bg.append("feComposite").attr("in", "SourceGraphic");
        }
        this.selected_object.setAttribute("filter", `url(#${newVal})`);
        this.set_file_changed(true);
        return;
      }
      if (this.selected_object.getAttribute("filter") != undefined) {
        this.selected_object.removeAttribute("filter");
        this.set_file_changed(true);
      }
    },
  },
  computed: {
    ...mapGetters(["selected_object"]),
    text_colors() {
      return [
        {
          id: "none",
          value: "none",
        },
        {
          id: "text_bg_yellow",
          value: "yellow",
        },
        {
          id: "text_bg_white",
          value: "white",
        },
        {
          id: "text_bg_orange",
          value: "orange",
        },
        {
          id: "text_bg_red",
          value: "red",
        },
      ];
    },
  },
  watch: {
    object_attributes: {
      handler: function(val, oldVal) {
        let _this = this;
        setTimeout(function() {
          _this.set_inputs_to_default();
        }, 200);
      },
    },
    selected_object: function(newVal, oldVal) {
      this.load_attributes();

      if (
        newVal != null &&
        newVal.nodeName == "text" &&
        newVal.getAttribute("filter") != undefined &&
        newVal.getAttribute("filter").includes("url")
      ) {
        let color = newVal
          .getAttribute("filter")
          .replace(/url\(\#/gi, "")
          .replace(/\)/gi, "");
        let result = this.text_colors.filter((c) => c.id == color);
        if (result.length > 0) this.text_bg_color = result[0].id;
        else this.text_bg_color = "none";
      }
      //this.load_transformation();
    },
    rotation_deg: function(newVal) {
      this.rotate_object(newVal);
    },
    text_bg_color: function(newVal) {
      this.update_text_bg(newVal);
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
</style>
