<template>
  <div>
    <!--    <el-button-->
    <!--      type="primary"-->
    <!--      @click="openDialog"-->
    <!--      size="mini"-->
    <!--    >-->
    <!--      Open User Assignment Dialog-->
    <!--    </el-button>-->

    <!-- Dialog -->
    <el-dialog
      :visible.sync="dialog_visible"
      title="Assign Users"
      @close="close_dialog"
    >
      <el-form ref="form">
        <!-- First Drop-down for List Selection -->
        <el-form-item label="Select List">
          <el-select
            v-model="selected_list"
            placeholder="Select list"
            @change="get_users_by_list"
          >
            <el-option
              v-for="list in lists"
              :key="list.id"
              :label="list.name"
              :value="list.id"
            />
          </el-select>
        </el-form-item>

        <!-- Section for Assigned Users with Badges -->
        <el-form-item label="Assigned Users">
          <div
            v-for="user in assigned_users"
            :key="user.id"
            class="badge"
          >
            <el-badge
              :value="user.name"
              class="item"
            >
              <el-button
                type="danger"
                size="mini"
                circle
                icon="el-icon-close"
                @click="delete_user_from_list(user.id)"
              />
            </el-badge>
          </div>
        </el-form-item>

        <!-- Second Drop-down for Suggested Users Selection -->
        <el-form-item label="Suggested Users">
          <el-select
            v-model="selected_suggested_users"
            placeholder="Select users"
            multiple
          >
            <el-option
              v-for="user in suggested_users"
              :key="user.id"
              :label="user.name"
              :value="user.id"
            />
          </el-select>
        </el-form-item>

        <!-- Button to Assign Selected Users -->
        <el-form-item>
          <el-button
            type="primary"
            @click="assign_users"
          >
            Assign Users
          </el-button>
        </el-form-item>
      </el-form>
      <!-- Footer of the Dialog -->
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialog_visible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="handle_ok"
        >OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editor_store} from 'store/modules/editor';
import { mapGetters} from 'vuex';
export default {
  name: 'JobListUsers',
  store: editor_store,
  data() {
    return {
      dialog_visible: false,
      lists: [], // Filled with list data
      selected_list: null,
      assigned_users: [], // Assigned users for the selected list
      suggested_users: [], // Users suggested based on selected list
      selected_suggested_users: [], // Users selected from the suggested users
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
    ])
  },
  mounted() {
    this.fetch_jobs_lists();
  },
  methods: {
    openDialog() {
      this.dialog_visible = true;
    },
    close_dialog() {
      this.dialog_visible = false;
    },
    handle_ok() {
      // Emit the 'load_changes' event when the OK button is clicked
      this.$emit('load_changes');
      this.close_dialog();
    },
    fetch_jobs_lists() {
      // Fetch job lists based on proposal_id
      // Shady: I stopped here
      let proposal_id = this.proposal.id;
      this.$http
        .get(`/api/job_lists/job_lists_by_proposal?proposal_id=${proposal_id}`)
        .then((response) => {
          this.lists = response.data;
        });
    },
    get_users_by_list() {
      const listId = this.selected_list;
      if (listId) {
        this.$http.get(`/api/job_list_users/fetch_job_list_users?job_list_id=${listId}` , (users) => {
          this.assigned_users = users;
        });

        // Fetch suggested users as well
        this.$http.get(`/api/job_list_users/fetch_job_list_suggested_users?job_list_id=${listId}`, (users) => {
          this.suggested_users = users;
        });
      }
    },
    // Delete a user from the assigned users list
    delete_user_from_list(userId) {
      this.$http('/unassign_user', userId, () => {
        this.assigned_users = this.assigned_users.filter(
          (user) => user.id !== userId
        );
      });
    },
    // Assign selected suggested users to the current list
    assign_users() {
      const users_to_assign = this.selected_suggested_users;
      if (users_to_assign.length > 0) {
        this.$http.post(
          '/assign-users',
          this.selected_list,
          { user_ids: users_to_assign },
          () => {
            this.get_users_by_list();
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.badge {
  margin-bottom: 10px;
}

.badge .item {
  margin-right: 10px;
}
</style>
