<template>
  <div
    id="jobs_calendar"
    class="jobs_calendar"
  >
    <strong>
      JOBS CALENDAR
    </strong>
    <hr>

    <el-row
      v-show="show_search_criterias"
      v-loading="loading"
    >
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <div class="search_criterias">
          Date:
          <el-date-picker
            v-model="selected_date_range"
            size="small"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            style="width: auto !important;"
            :picker-options="pickerOptions2"
            @change="onChangeDate"
          />
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <div class="search_criterias">
          Type
          <el-select
            v-model="selected_types"
            multiple
            collapse-tags
            size="small"
          >
            <el-option
              label="Current"
              value="current"
            />
            <el-option
              label="Future"
              value="future"
            >
              Future
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <div class="search_criterias">
          Category
          <el-select
            v-model="selected_categories"
            multiple
            placeholder="Select Category"
            collapse-tags
            size="small"
            style="min-width: 210px;"
          >
            <el-option
              v-for="category in categories_list"
              :key="category.id"
              :label="category.name.toUpperCase()"
              :value="category.id"
            />
          </el-select>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <div class="search_criterias">
          Users
          <el-select
            v-model="selected_users"
            multiple
            collapse-tags
            size="small"
            @change="selected_users_changed"
          >
            <el-option
              v-for="item in users_list"
              :key="item.id"
              :label="item.user_name"
              :value="item.id"
            />
          </el-select>

          <el-checkbox
            v-model="users_checked"
            @change="users_checked_changed"
          >
            Select/Unselect all users
          </el-checkbox>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <el-button
          size="small"
          type="success"
          @click="search_jobs"
        >
          Search
        </el-button>
      </el-col>
    </el-row>

    <el-row v-show="!show_search_criterias">
      <el-button
        class="right"
        size="small"
        type="success"
        @click="search_jobs"
      >
        Refresh
      </el-button>
    </el-row>
    <el-tabs
      v-model="selected_tab"
      v-loading="loading"
      type="border-card"
    >
      <el-tab-pane
        v-show="show_overdue_tab"
        label="Overdue tasks"
        name="overdue_tasks"
      >
        <JobProposalsActionList
          title="Tasks list"
          :tasks_list="overdue_tasks"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Calendar view"
        :lazy="true"
        name="calendar_view"
      >
        <el-row>
          <el-radio-group
            v-model="calendar_selected_group"
            size="mini"
            @change="update_calendar_view"
          >
            <el-radio-button label="Users view" />
            <el-radio-button
              v-show="show_search_criterias"
              label="Jobs view"
            />
          </el-radio-group>
          <div class="right">
            <el-tag
              type="success"
              effect="dark"
              size="mini"
            >
              ACTIVE TASKS
            </el-tag>
            <el-tag
              style="background-color: #cfcfc4 !important; border:none;"
              effect="dark"
              size="mini"
            >
              FUTURE
            </el-tag>
            <el-tag
                style="background-color: #F56C6C !important;color: #ffffff;"
                size="mini"
            >
              LOCKED
            </el-tag>

            <el-tag
              type="warning"
              effect="dark"
              size="mini"
            >
              SELECTED
            </el-tag>
          </div>
        </el-row>
        <el-row>
          <FullCalendar
            :config="calendar_config"
            :editable="false"
            :default-view="calendar_selected_view"
            :views="calendar_views"
            :plugins="calendar_plugins"
            :now-indicator="true"
            :calendar-weekends="false"
            :week-numbers="true"
            min-time="07:00:00"
            max-time="18:00:00"
            :weekends="false"
            :all-day-slot="true"
            :resource-label-text="resouce_label"
            :resources="calendar_resources"
            :events="calendar_events"
            :business-hours="business_hours"
            :header="header"
            @eventRender="event_render"
          />
        </el-row>
      </el-tab-pane>
      <el-tab-pane
        label="Tabular view"
        name="tabular_view"
      >
        <el-row>
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="group in grouped_users_tasks"
              :key="group.id"
              :name="group.user.id"
            >
              <template slot="title">
                <el-row style="width:100%">
                  {{ group.user.title.toUpperCase() }}

                  <div
                    class="right"
                    style="margin-right: 20px;"
                  >
                    <span>
                      <b>Active tasks: </b>
                      <span style="font-weight: normal; ">{{
                        group.active_tasks.length
                      }}</span>
                    </span>

                    <span>
                      <b>Future tasks: </b>
                      <span style="font-weight: normal; ">
                        {{ group.future_tasks.length }}
                      </span>
                    </span>
                  </div>
                </el-row>
              </template>
              <h3>
                <strong>ACTIVE</strong>
              </h3>
              <hr>
              <br>
              <JobProposalsActionList
                title="TODAY"
                :tasks_list="active_list(group.active_tasks, 'TODAY')"
              />
              <br>
              <JobProposalsActionList
                title="TOMORROW"
                :tasks_list="active_list(group.active_tasks, 'TOMORROW')"
              />
              <br>
              <JobProposalsActionList
                title="ACTIVE FUTURE"
                :tasks_list="active_list(group.active_tasks, 'FUTURE')"
              />
              <br>
              <br>
              <el-divider content-position="left">
                FUTURE TASKS
              </el-divider>
              <el-table
                :data="group.future_tasks"
                style="width: 100%"
              >
                <el-table-column
                  prop="proposal_id"
                  label="Proposal Id"
                  sortable
                >
                  <template slot-scope="scope">
                    <el-link
                      :href="`/job_view?id=${scope.row.proposal_id}`"
                      target="_blank"
                    >
                      {{ scope.row.proposal_id }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="account_name"
                  label="Account"
                  sortable
                />
                <el-table-column
                  prop="job_name"
                  label="Job Name"
                  sortable
                />
                <el-table-column
                  prop="task_name"
                  label="Task"
                  sortable
                />
                <el-table-column
                  prop="start_date"
                  label="Start"
                  sortable
                />
                <el-table-column
                  prop="end_date"
                  label="End at"
                  sortable
                />
                <el-table-column
                  prop="slot_duration"
                  label="Duration"
                  sortable
                >
                  <template slot-scope="scope">
                    {{ scope.row.slot_duration }} minutes
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </el-tab-pane>
      <el-tab-pane
        label="More tasks"
        :lazy="true"
      >
        <MoreWork />
      </el-tab-pane>
      <el-tab-pane label="To be completed Today" name="todaydue_jobs" :lazy="true">

        <TodayDueJobs/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import MoreWork from 'components/job_proposals/MoreWork';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import JobProposalsActionList from 'components/job_proposals/JobProposalsActionList';
import TodayDueJobs from '../proposal/TodayDueJobs';
import {MinutesToHours} from "../../utilities/Time";

export default {
  name: 'JobsCalendar',
  components: { TodayDueJobs, FullCalendar, JobProposalsActionList, MoreWork },
  props: ['user_id', 'show_filter'],
  data() {
    return {
      loading: false,
      activeNames: [],
      show_overdue_tab: false,
      selected_tab: 'calendar_view',
      users_checked: false,
      overdue_tasks: [],
      jp_extension: {
        jp_id: '',
        description: '',
        user_id: '',
        user: { id: '', user_name: '' },
        status: { id: '', name: '' },
      },
      jp_extensions: [],
      extension_dialog: false,
      slotDuration: '01:00:00',
      resouce_label: 'Jobs',
      suggested_results: [],
      selected_date_range: '',
      selected_types: ['current', 'future'],
      calendar_selected_group: 'Users view',
      users_list: [],
      selected_users: [],
      categories_list: [],
      selected_categories: [],
      calendar_resources: [],
      pickerOptions2: {
        shortcuts: [
          {
            text: 'All time',
            onClick(picker) {
              picker.$emit('pick', ['', '']);
            },
          },
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next week',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      header: {
        left: 'prev,today,next',
        center: 'title,zoomOut,zoomIn',
        right:
          'resourceTimeGridDay,dayGridMonth,resourceTimelineWeek,resourceTimelineMonth',
      },
      business_hours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        dow: [1, 2, 3, 4, 5], // Monday - Friday
        start: '08:00', // a start time (10am in this example)
        end: '17:00', // an end time (6pm in this example)
      },
      calendar_views: {
        timelineThreeDays: {
          type: 'timeline',
          duration: { days: 3 },
        },
        resourceTimeGridDay: {
          slotDuration: '00:05:00',
        },
        agendaDay: {
          slotDuration: '00:10:00',
        },
        resourceTimelineWeek: {
          slotDuration: '00:30:00',
        },
        resourceTimelineMonth: {
          duration: { month: 1 },
        },
      },
      calendar_plugins: [
        resourceDayGridPlugin,
        resourceTimeGridPlugin,
        dayGridPlugin,
        resourceTimelinePlugin,
        interactionPlugin,
      ],
      calendar_events: [],
      calendar_selected_view: 'resourceTimeGridDay',
      calendar_config: {
        eventDragStop: function(event, jsEvent, ui, view) {},
      },
    };
  },
  computed: {
    show_search_criterias() {
      if (location.pathname.indexOf('project_planner_view') > -1) return true;
      return this.show_filter == '1';
    },
    selected_types_ids() {
      let types_ids = [];
      this.selected_types.forEach((el, idx) => {
        types_ids.push(el == 'current' ? 1 : 0);
      });
      return types_ids;
    },
    grouped_users_tasks() {
      let arr = [];
      let idx = 0;
      if (this.suggested_results.resources == undefined) return [];
      this.suggested_results.resources.users.forEach((el, idx) => {
        //crm_users.id,user_name as title
        let user = el;
        let active_tasks = this.suggested_results.tasks.filter(
          (c) => c.resources.user_id.includes(el.id) == true && c.is_active == 1
        );
        let future_tasks = this.suggested_results.tasks.filter(
          (c) => c.resources.user_id.includes(el.id) == true && c.is_active == 0
        );
        if (active_tasks.length > 0 || future_tasks.length > 0)
          arr.push({
            id: idx++,
            user: user,
            active_tasks: active_tasks,
            future_tasks: future_tasks,
          });
      });
      return arr;
    },
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        new Date(
          this.current_date.getFullYear(),
          this.current_date.getMonth() + 1,
          0
        ).getDate()
      );
    },
  },
  watch: {
    calendar_selected_group(newVal) {
      this.resouce_label = newVal.split(' ')[0];
      this.update_view();
    },
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.set_inputs_to_default();
    }, 300);
    this.load_categories();
    this.selected_date_range = ['', ''];
  },
  methods: {
    onChangeDate() {
      if(this.selected_date_range === null) {
        this.selected_date_range = ['', ''];
      }
    },
    active_list(list, type) {
      let today_end_date = new Date();
      today_end_date.setHours(17, 0);
      let tomorrow_end_date = new Date();
      tomorrow_end_date.setHours(17, 0);
      tomorrow_end_date.setDate(tomorrow_end_date.getDate() + 1);
      switch (type) {
      case 'TODAY':
        return list.filter(
          (c) =>
            new Date(c.start_time) < today_end_date ||
              new Date(c.end_time) < today_end_date
        );
        break;
      case 'TOMORROW':
        return list.filter((c) => new Date(c.start_time) > today_end_date);
        break;
      case 'FUTURE':
        return list.filter((c) => new Date(c.start_time) > tomorrow_end_date);
        break;
      }
    },
    load_overdue_tasks() {
      this.overdue_tasks = [];
      this.$http.post('/overdue_tasks_by_users', {users_ids: this.selected_users}).then((response) => {
        if (response.data.length > 0) {
          this.overdue_tasks = response.data;
        }
      });
    },
    users_checked_changed(val) {
      if (val == true) this.selected_users = this.users_list.map((c) => c.id);
      else this.selected_users = [];
    },
    selected_users_changed(val) {
      if (this.selected_users.length == 0) return;
      if (this.selected_users.length < this.users_list.length)
        this.users_checked = false;
      else if (this.selected_users.length == this.users_list.length)
        this.users_checked = true;
    },
    search_jobs() {
      // date range , type , categories , users
      let params = {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
        jobs_types: this.selected_types_ids,
        users_ids: this.selected_users,
        categories: this.selected_categories,
      };
      this.loading = true;
      this.$http
        .post('/fetch_jobs_calendar', { filter: params })
        .then((response) => {
          this.loading = false;
          this.suggested_results = response.data.result;
          this.update_view();
        });

      this.load_overdue_tasks();
    },
    update_calendar_view() {},
    update_view() {
      let _this = this;
      this.calendar_resources = [];
      this.calendar_events = [];
      let resource_id_col = 'list_id';

      if (this.calendar_selected_group == 'Jobs view') {
        this.calendar_selected_view = 'resourceTimelineMonth';
        resource_id_col = 'proposal_id';
        this.suggested_results.jobs.forEach((element, id) => {
          let total_hours = element.pending_hours + element.completed_hours;
          let frac = element.pending_hours / total_hours;
          let completed_percentage = 100 - 100 * frac;
          let pending_percentage = 100 * frac;

          let display_time = `[${element.completed_hours}/${total_hours} minutes]`;
          if(this.suggested_results.is_hours) {
            let display_completed_hours = `${MinutesToHours.hours(element.completed_hours)}h ${MinutesToHours.minutes(element.completed_hours)}min`;
            let display_total_hours = `${MinutesToHours.hours(total_hours)}h ${MinutesToHours.minutes(total_hours)}min`;
            display_time = `[ ${display_completed_hours} / ${display_total_hours} ]`
          }

          _this.calendar_events.push({
            title:
              `#${element.id} ` +
              element.name +
              ` ${display_time} ${parseInt(
                completed_percentage
              )}%`,
            start: element.start_time,
            end: element.end_time,
            resourceId: element.id,
            proposal_id: element.id,
            completed_percentage: completed_percentage,
            pending_percentage: pending_percentage,
            total_hours: total_hours,
            url: element.link,
            eventType: 'Job',
            is_date_locked: element.is_date_locked,
            // classNames: element.class_names,
          });
        });
      } else {
        this.calendar_selected_view = 'resourceTimelineWeek';
        this.suggested_results.tasks.forEach((el, id) => {
          if (this.calendar_selected_group == 'Users view') {
            resource_id_col = 'user_id';
          }

          this.calendar_events.push({
            id: el.id,
            title: el.name,
            start: el.start_time,
            end: el.end_time,
            resourceId: el.resources[resource_id_col],
            proposal_id: el.resources.proposal_id,
            link: el.link,
            startEditable: false,
            durationEditable: false,
            eventType: 'CurrentTask',
            url: el.link,
            is_active: el.is_active,
            is_date_locked: el.is_date_locked,
            classNames: el.class_names,
          });
        });
      }

      if (_this.calendar_selected_group == 'Users view')
        this.calendar_resources = this.suggested_results.resources.users;
      else if (_this.calendar_selected_group == 'Jobs view')
        this.calendar_resources = this.suggested_results.resources.jobs;
    },
    event_mouseover(event) {
      document
        .querySelectorAll('.selected_proposal_event')
        .forEach(function(el, idx) {
          el.classList.remove('selected_proposal_event');
        });

      document
        .querySelectorAll(
          `[proposal-id='${event.target.parentElement.parentElement.getAttribute(
            'proposal-id'
          )}']`
        )
        .forEach(function(el, idx) {
          el.classList.add('selected_proposal_event');
        });
    },
    event_render(event, element) {
      event.el.setAttribute('title', event.event.title);
      event.el.setAttribute('href', event.event.url);
      event.el.setAttribute('target', '_blank');
      event;

      event.el.setAttribute(
        'proposal-id',
        event.event.extendedProps.proposal_id
      );

      if (event.event.extendedProps.eventType != 'Job') {
        event.el.setAttribute(
          'style',
          `background-image: linear-gradient(to right, #67c23b ${event.event.extendedProps.completed_percentage}% , orange ${event.event.extendedProps.pending_percentage}%)`
        );
        event.el.firstElementChild.addEventListener(
          'mouseover',
          this.event_mouseover
        );
      }
      console.log(event.event)

      if (event.event.extendedProps.is_active == 0){
        event.el.setAttribute(
          'style',
          'background-color: #cfcfc4 !important;color: #666 !important'
        );
      } else if (event.event.extendedProps.is_date_locked == 1){
        event.el.setAttribute(
            'style',
            'background-color: #F56C6C !important;'
        );
      } else {
        event.el.setAttribute('style', 'background-color: #67c23a');
      }
    },
    load_categories() {
      let _this = this;
      let query_str = '';
      this.$http.get('/category_tasks_list').then(function(response) {
        if (response.status == 200) {
          this.categories_list = response.data;
          this.selected_categories = this.categories_list.map(function(
            el,
            idx
          ) {
            return el.id;
          });
          this.load_users();
        }
      });
    },
    load_users() {
      this.$http.get('/users_list').then((response) => {
        this.users_list = response.data.list;
        if (this.user_id != undefined) {
          this.selected_users = [parseInt(this.user_id)];
          this.search_jobs();
          this.activeNames = [parseInt(this.user_id)];
        }
      });
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll('#jobs_calendar .el-input')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>
<style scoped>
.search_criterias {
  margin: 5px;
}
</style>
<style>
.selected_proposal_event {
  background-color: orange !important;
}
.existing_task:hover {
  min-width: fit-content;
  background-color: #0999ca;
  z-index: 1000;
}

.fc-timeline-event:hover {
  min-width: fit-content;
  background-color: #0999ca;
  z-index: 1000;
}
</style>
