<template>
  <div>
    <el-row :gutter="20">
      <el-col class="sales__title" :span="8" :xs="24">
        {{ working_file.name }}
        <p v-show="working_file.id !== null" >
          Total Sale Price: ${{ total_sale_price }}
        </p>
      </el-col>

      <el-col  v-show="working_file.id !== null" :span="3" :xs="24" >
        Quantity:
        <input v-model.number="working_file.qty"
               step="1"
               type="number"
               @keypress.enter.prevent="prevent_enter"
               @change="update_qty"/>
      </el-col>
      <el-col  v-show="working_file.id !== null" :span="5" :xs="24" >
        Quantity Unit:
          <el-select v-model="quantity_unit_id"
                     class="block"
                     placeholder="Select"
                     :width="100"
                     @change="update_unit">
            <el-option
                v-for="quantity_unit in working_file.quantity_units"
                :key="quantity_unit.id"
                :label="quantity_unit.name"
                :value="quantity_unit.id">
            </el-option>
          </el-select>
      </el-col>
      <el-col  v-show="working_file.id !== null" :span="8" :xs="24" >
        Total(Matched quote):
        <input v-model.number="working_file.matched_quote"
               step="0.01"
               type="number"
               @keypress.enter.prevent="prevent_enter"
               @change="update_matched_quote"/>
      </el-col>
    </el-row>
    <el-row v-show="working_file.id !== null">
      Note
      <jodit-editor ref="note_editor" v-model="note_content"
                    :buttons="buttons"
                    id="working_file_note_editor"
                    :config="jodit_config"/>
    </el-row>
  </div>
</template>
<script>
import CommonJoditMixin from "mixins/CommonJoditMixin";

export default {
  name: "WorkingFile",
  mixins: [CommonJoditMixin],
  props: [ "working_file", "total_sale_price"],
  mounted() {
    this.quantity_unit_id = this.working_file.quantity_unit_id;
    if (this.working_file.id !== null) {
      this.note_content = this.working_file.note;
      this.$refs.note_editor.editor.events.on('outsideClick', this.update_note);
    }
  },
  data() {
    return {
      quantity_unit_id: null,
      note_content: "",
    }
  },
  methods: {
    update_note(e) {
      if(this.note_content !== this.working_file.note) {
        this.working_file.note = this.note_content;
        this.update_working_file({
          note: this.working_file.note
        });
      }
    },
    update_matched_quote() {
      this.update_working_file({
            matched_quote: this.working_file.matched_quote
          });
      this.$emit("changedTotal");
    },
    update_qty() {
      this.update_working_file({
        qty: this.working_file.qty
      });
    },
    update_unit(selected_id) {
      this.quantity_unit_id = this.working_file.quantity_unit_id = selected_id;
      this.update_working_file({
        quantity_unit_id: this.quantity_unit_id
      });
    },
    prevent_enter(e) {
      e.preventDefault();
      e.stopPropagation();
      e.target.blur();
    },
    update_working_file(params) {
      this.$http.patch(`/update_working_file_details/${this.working_file.id}`,
          {
            file_details: params
          })
          .then((response) => {
            this.$message({
              message: 'changed.',
              type: 'success'
            });
          }, (response) => {
            this.$message({
              type: "error",
              message: "Error happened while saving details.",
            });
          });
    }
  },
}
</script>

