<template>
  <div id="inline">
    <el-link
      :underline="false"
      @click.native.prevent="dialogVisible = true,get_vacation_dates()"
    >
      Auto Reply - Out of office
    </el-link>

    <el-dialog
      title="Auto Reply - Out of office email"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      append-to-body
      @opened="set_default_input()"
    >
      <el-date-picker
        v-model="date_range"
        align="right"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        type="datetimerange"
        format="dd.MM.yyyy HH:mm:ss"
        range-separator="/"
        :default-time="['08:00:00', '17:00:00']"
        class=" browser-default space"
      />
      <el-input
        v-model="message"
        type="textarea"
        :autosize="{ minRows: 7, maxRows: 7 }"
        placeholder="email message"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="danger"
          size="mini"
          @click="delete_vacation_dates()"
        >Delete</el-button>
        <el-button
          size="mini"
          @click="dialogVisible = false"
        >Cancel</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="set_vacation_dates(),dialogVisible = false"
        >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: 'EmailVacation',
  props: {
    mailbox_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      date_range: [],
      message: ''
    };
  },
  computed: {},
  methods: {
    get_vacation_dates() {
      this.$http.get('/get_vacation_dates/' + this.mailbox_id).then(response => {
        this.date_range = response.data.data.date_range;
        this.message = response.data.data.message;
      });
    },
    set_vacation_dates() {
      this.$http.post('/set_vacation_dates', {
        mailbox_account_id: this.mailbox_id,
        date_range: this.date_range,
        message: this.message
      })
        .then(
          response => {
            this.$message({
              message: response.data.message,
              type: 'success'
            });
          },
          response => {
            console.log(response.data.errors);
            this.$message({
              message: response.data.errors,
              type: 'error'
            });
          }
        );
    },
    delete_vacation_dates() {
      this.$http.delete(`/delete_vacation_dates/${this.mailbox_id}`);
      this.message = '';
      this.date_range = [];
    },
    handleClose(done) {
      done();
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
#inline {
  display: inline;
}
.space { margin-bottom: 10px; }
</style>
