<template>
  <div id="leave-requests-user">
    <span
      v-if="leave_requests.length == 0"
      class="margin-left"
    >No Holidays and leave.</span>
    <el-table
      v-if="leave_requests.length > 0"
      ref="multipleTable"
      :data="leave_requests"
      style="width: 100%"
      stripe
    >
      <el-table-column
        label="From - To"
        property="from_date"
      >
        <template #default="scope">
          {{ scope.row.from_date_formatted }}<br>
          {{ scope.row.to_date_formatted }}
        </template>
      </el-table-column>
      <el-table-column
        label="Type"
        property="requestable"
      >
        <template #default="scope">
          {{ scope.row.requestable }}
        </template>
      </el-table-column>
      <el-table-column
        label="hours/dollars"
      >
        <template #default="scope">
          <span>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
      >
        <template slot-scope="scope">
          {{ request_statuses.find(s => s.id === leave_requests[scope.$index].request_status_id).name }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {leave_requests} from '../../api/leave_requests';

export default {
  name: 'LeaveRequestsUserTransaction',
  props: {
    start: {
      type: [String],
      required: true
    },
    end: {
      type: [String],
      required: true
    },
    crm_user_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      leave_requests: [],
      request_statuses: []
    };
  },
  created() {
    this.get_leave_requests();
    this.get_request_statuses();
  },
  methods: {
    get_request_statuses(){
      this.$http.get('/request_statuses')
        .then(response => {
          this.request_statuses = response.data;
        });
    },
    get_leave_requests(){
      leave_requests(this, this.start, this.end, this.crm_user_id ).then((response)=> {
        this.leave_requests = response.data;
      });
    }
  }
};

</script>

<style scoped>
  #leave-requests-user{
    width: 100%;
  }
  .margin-left {margin-left: 10px; }
</style>
