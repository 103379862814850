import {Request} from '../utilities/Request';

export function pay_cycles(this_obj) {
  return Request.get(
    this_obj,
    '/api/pay_cycles',
  );
}

export function create_pay_cycle(this_obj, new_pay_cycle) {
  return Request.post(
    this_obj,
    '/api/pay_cycles',
    null,
    {
      pay_cycle: new_pay_cycle
    }
  );
}

export function add_selected_employees(this_obj, id, selected_employees) {
  return Request.post(
    this_obj,
    '/api/pay_cycles/add_selected_employees',
    null,
    {
      pay_cycle_id: id,
      selected_employees: selected_employees
    }
  );
}

export function remove_employee_from_pay_cycle(this_obj, id) {
  return Request.patch(
    this_obj,
    `/crm_users/${id}`,
    null,
    {
      pay_cycle_id: null
    }
  );
}

export function delete_pay_cycle(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/pay_cycles/${id}`,
    null
  );
}

export function pay_cycle_dates(this_obj) {
  return Request.post(
    this_obj,
    '/api/pay_cycles/pay_cycle_dates',
    null
  );
}
