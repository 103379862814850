<template>
  <div id="add-employees">
    <el-button
      type="primary"
      size="mini"
      @click="employee_dialog = true, add_employees_to_pay_cycle(pay_cycle_id)"
    >
      Add Employees
    </el-button>
    <el-dialog
      width="50%"
      :visible.sync="employee_dialog"
      title="Add Employees"
      @opened="set_default_input"
    >
      <el-table
        ref="multipleTable"
        :data="employee_list.filter(user => user.inactive === false && user.pay_cycle_id === null)"
        style="width: 100%"
        stripe
        @selection-change="handleSelectionChange"
        @opened="get_employees()"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          label="Name"
          property="name"
        >
          <template #default="scope">
            {{ scope.row.user_name }} {{ scope.row.user_surname }}
          </template>
        </el-table-column>
        <el-table-column
          label="Payslip Email"
        >
          <template #default="scope">
            {{ scope.row.payslip_email }}
          </template>
        </el-table-column>
      </el-table>
      <el-divider />
      <div>
        <el-button
          type="primary"
          size="small"
          style="float:right;margin-left:15px;"
          @click="add_selected_employees()"
        >
          Add Employee
        </el-button>
        <el-button
          style="float:right;"
          size="small"
          @click="clear_selection()"
        >
          Clear selection
        </el-button>
        <br>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {remove_employee_from_pay_cycle} from '../../api/pay_cycles';
import {set_default_input} from '../../utilities/DefaultInput';
export default {
  name: 'AddEmployees',
  props: {
    pay_cycle_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      employee_list: [],
      employee_dialog: false,
      selected_employees: [],
      selected_pay_cycle_id: null
    };
  },
  created() {
    this.get_employees();
  },
  methods: {
    set_default_input,
    get_employees() {
      this.$http.get('/api/pay_cycles/employees').then((response) => {
        this.employee_list = response.data;
      });
    },
    edit_employee(row) {
      window.open(`/crm_users/${row.id}/edit`, '_blank');
    },
    remove_employee(row) {
      remove_employee_from_pay_cycle(this, row.id).then(()=> {
        this.employee_list.splice( this.employee_list.findIndex(function(i){
          return i.id === row.id;
        }), 1);
        this.get_employees();
        this.$emit('reload_employees');
      });
    },
    clear_selection() {
      this.$refs.multipleTable.clearSelection();
    },
    add_employees_to_pay_cycle(id){
      this.selected_pay_cycle_id = id;
      this.$emit('reload_employees');
    },
    add_selected_employees(){
      this.$http.post('/api/pay_cycles/add_selected_employees', {  pay_cycle_id:  this.selected_pay_cycle_id,
        selected_employees: this.selected_employees}).then(response => {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.get_employees();
        this.employee_dialog = false;
        this.$emit('reload_employees');
      }, function (response) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: response.data.message
        });
      });

    },
    handleSelectionChange(val) {
      this.selected_employees = val;
    }
  }
};
</script>
<style scoped>
th {font-size: 12px;font-weight: normal;color: gray;width: 25%;}
td, th {padding: 0px;}
.el-card { margin-bottom: 20px;}
</style>
