<template>
  <div
    id="import_pdf_preview"
    v-loading="dialog_loading"
    style="height: 100%;"
  >
    <el-container style="height: 100%;">
      <el-header style="height: auto">
        <el-row>
          <el-col :span="12">
            <el-collapse accordion>
              <el-collapse-item
                title="File manager"
                name="filemanager"
              >
                <div>
                  <el-button
                    style="float: right; margin: 3px"
                    icon="el-icon-refresh"
                    size="mini"
                    @click="load_filemanager_files"
                  />
                  <el-table
                    :data="file_managers_list"
                    style="width: 100%"
                  >
                    <el-table-column
                      prop="name"
                      label="Name"
                      width="300"
                    />
                    <el-table-column
                      prop="size"
                      label="Size"
                      width="80"
                    />
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          @click="load_filemanager_file(scope.$index, scope)"
                        >
                          Select
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="File upload"
                name="fileupload"
              >
                <el-upload
                  class="upload-demo"
                  action=""
                  :limit="5"
                  multiple
                  :on-preview="load_pdf_file"
                  accept=".pdf"
                  :auto-upload="false"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                  >
                    Select file
                  </el-button>
                  <div
                    slot="tip"
                    class="el-upload__tip"
                  >
                    Select PDF file and click <b>Import file</b>
                  </div>
                </el-upload>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :span="6">
            <div
              class="demo-input-suffix"
              style="margin: 3px"
            >
              <span class="demo-input-label">Import type</span>
              <el-select
                v-model="selected_type"
                size="mini"
              >
                <el-option
                  v-for="item in import_types"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
            <div
              v-show="selected_type == 'image'"
              class="demo-input-suffix"
              style="margin: 3px"
            >
              <span class="demo-input-label">Resolution</span>
              <el-select
                v-model="selected_resolution"
                size="mini"
              >
                <el-option
                  v-for="item in resolution_types"
                  :key="item.dpi"
                  :label="item.type"
                  :value="item.dpi"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button
              class="right"
              type="danger"
              size="mini"
              @click="reset_specs"
            >
              Reset specs
            </el-button>
            <el-button
              v-show="selected_page != ''"
              class="right"
              type="info"
              size="mini"
              @click="populate_specs"
            >
              Populate specs
            </el-button>
            <el-button
              class="right"
              type="success"
              size="mini"
              @click="import_pdf_pages"
            >
              Import
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside
          width="200px"
          style="background-color: rgb(238, 241, 246)"
        >
          <div id="vertical_panel_div">
            <el-card
              v-for="image in images_list"
              :key="image.id"
              :body-style="{ padding: '0px' }"
              style="padding: 12px;"
            >
              <div
                style="text-align: center"
                :class="selected_page.id === image.id ? 'selected_page' : ''"
              >
                <img
                  width="55px"
                  :src="image.thumb"
                  style="border: 1px solid #e4dede; cursor: pointer;"
                  @click="load_svg_content(image)"
                >
                <br>
                <el-checkbox v-model="image.selected" />
                <el-input
                  v-model="image.name"
                  size="mini"
                  style="width: 125px"
                />
                <br>
                <span>Rotation</span>
                <el-select v-model="image.rotation" placeholder="Select" size="mini" style="width: 80px" @change="load_svg_content(image)">
                  <el-option
                    v-for="item in rotations_list"
                    :key="item"
                    :label="item"
                    :value="item">                    
                    <i class="el-icon-picture" :style="'transform: rotate(' + item + 'deg); display: inline-block; font-size: 20px;'" />
                    <span style="margin-left: 5px;">{{ item }}°</span>
                  </el-option>
                </el-select>                
              </div>
            </el-card>
          </div>
        </el-aside>

        <el-main v-loading="main_content_loading">
          <div
            v-show="selected_page != ''"
            style="text-align: start;"
          >
            <h3>
              <span>
                {{ selected_file.name }}
              </span>
              <div class="right">
                <el-button
                  type="primary"
                  size="mini"
                  @click="scale_by_points"
                >
                  Choose points
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  @click="reset_changes"
                >
                  Reset changes
                </el-button>
              </div>
            </h3>
            <strong>Page dimensions:</strong>

            <span style="font-weight: 500;">Actual</span>
            <el-row :gutter="10">
              <el-col :span="2">
                Width:
              </el-col>
              <el-col
                :span="4"
              >
                {{selected_page.rotation == 0  || selected_page.rotation == 180 ? (selected_page.actual_width / mm_to_px) : (selected_page.actual_height / mm_to_px) }}
              </el-col>
              <el-col :span="2">
                Height:
              </el-col>
              <el-col :span="4">
                {{selected_page.rotation == 0  || selected_page.rotation == 180 ? (selected_page.actual_height / mm_to_px) : (selected_page.actual_width / mm_to_px) }}
              </el-col>
            </el-row>
            <span style="font-weight: 500;">Target specs:</span>
            <el-row :gutter="10">
              <el-col :span="2">
                Width
              </el-col>
              <el-col
                :span="4"
              >
                <el-input v-if="selected_page.rotation == 0  || selected_page.rotation == 180"
                  v-model.number="svg_target_width"
                  size="mini"
                  @change="update_svg_content('width')"
                >
                  <template slot="append">
                    mm
                  </template>
                </el-input>
                <el-input v-else
                  v-model.number="svg_target_height"
                  size="mini"
                  @change="update_svg_content('height')"
                >
                  <template slot="append">
                    mm
                  </template>
                </el-input>
              </el-col>
              <el-col :span="2">
                Height:
              </el-col>
              <el-col
                :span="4"
              >
              <el-input v-if="selected_page.rotation == 0  || selected_page.rotation == 180"
                  v-model.number="svg_target_height"
                  size="mini"
                  @change="update_svg_content('height')"
                >
                  <template slot="append">
                    mm
                  </template>
                </el-input>
                <el-input v-else
                  v-model.number="svg_target_width"
                  size="mini"
                  @change="update_svg_content('width')"
                >
                  <template slot="append">
                    mm
                  </template>
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div id="import_pdf_selected_svg" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { editor_store, mm_to_px, xml_header } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { WorkspaceMixin } from 'mixins/WorkspaceMixin.js';
import {EventBus} from '../../utilities/EventBus';

export default {
  name: 'ImportPDFPreview',
  store: editor_store,
  mixins: [WorkspaceMixin],
  props: ['hide_pdf_preview_dialog'],
  data() {
    return {
      images_list: [],
      rotations_list: [0, 90, 180, 270],
      activeName: '',
      selected_type: 'image',
      selected_resolution: '150',
      import_types: ['vector', 'image'],
      resolution_types: [
        { type: 'Low (96dpi)', dpi: '96' },
        { type: 'Medium (150dpi)', dpi: '150' },
        { type: 'High (300dpi)', dpi: '300' },
      ],
      file_managers_list: [],
      svg: '',
      scaling_points: [],
      mm_to_px: 3.779527559,
      dialog_loading: false,
      main_content_loading: false,
      selected_file: '',
      svg_target_width: '',
      svg_target_height: '',
      selected_page: '',
    };
  },
  mounted() {
    this.images_list = [];
    let _this = this;
    this.load_filemanager_files();
    setTimeout(function() {
      _this.set_inputs_to_default();
    }, 500);
  },
  computed: {
    ...mapGetters(['proposal','get_active_working_file_category']),
  },
  methods: {
    ...mapActions(['append_files_list']),
    load_filemanager_file(idx, file) {
      let _this = this;
      let file_name = file.row.name;
      let params = { path: file.row.path };
      this.load_pdf_file(params);
      return;
    },
    load_filemanager_files() {
      let current_path = '';
      this.file_managers_list = [];
      this.dialog_loading = true;

      this.$http
        .get('/get_pdf_files_by_proposal', {
          params: { proposal_id: this.proposal.id },
        })
        .then(
          (response) => {
            this.file_managers_list = response.data.files;
            this.dialog_loading = false;
          },
          (response) => {
            this.$message({
              type: 'error',
              message: 'Error happened while loading files, please try again.',
            });
            this.dialog_loading = false;
          }
        );
    },
    update_svg_content(type) {
      let factor = 1;
      if (type == 'width') {
        factor =
          (this.svg_target_width * this.mm_to_px) /
          this.selected_page.actual_width;
        this.svg_target_height =
          (this.selected_page.actual_height * factor) / this.mm_to_px;
      } else {
        factor =
          (this.svg_target_height * this.mm_to_px) /
          this.selected_page.actual_height;
        this.svg_target_width =
          (this.selected_page.actual_width * factor) / this.mm_to_px;
      }
      this.selected_page.scale = factor;
      this.selected_page.target_width =
        this.selected_page.actual_width * factor;
      this.selected_page.target_height =
        this.selected_page.actual_height * factor;
      this.refresh_svg_boundary();
    },
    populate_specs() {
      this.images_list.forEach((el, idx) => {
        if (el.id != this.selected_page.id) {
          el.target_width = this.selected_page.target_width;
          el.target_height = this.selected_page.target_height;
          el.scale = this.selected_page.scale;
        }
      });
      this.$message({
        type: 'success',
        message: 'Specs have been populated for all files.',
      });
    },
    reset_specs() {
      this.images_list.forEach((el, idx) => {
        el.target_width = undefined;
        el.target_height = undefined;
        el.scale = 1;
      });
      this.$message({
        type: 'success',
        message: 'Specs have been resetted for all files.',
      });
    },
    import_pdf_pages() {
      let checked_items = this.images_list.filter((c) => c.selected == true);
      if (checked_items.length == 0) {
        this.$message({ type: 'warning', message: 'No selected page(s) !' });
        return;
      }
      this.main_content_loading = true;

      let formData = new FormData();
      if (this.selected_file.path)
        formData.append('path', this.selected_file.path);
      else formData.append('file', this.selected_file.raw);
      formData.append('proposal_id', this.proposal.id);
      formData.append('type', this.selected_type);
      formData.append('dpi', this.selected_resolution);
      if(this.get_active_working_file_category)
        formData.append('category_working_file_id', Number(this.get_active_working_file_category));
      // prepare params to be posted
      let list = checked_items.map(function(obj) {
        return {
          id: obj.id,
          name: obj.name,
          scale: obj.scale || 1,
          target_width: obj.target_width,
          target_height: obj.target_height,
          rotation: obj.rotation || 0,
        };
      });
      formData.append('images_list', JSON.stringify(list));
      this.$http.post('/import_pdf_pages', formData).then(
        (response) => {
          this.main_content_loading = false;
          this.append_files_list(response.body.working_files);
          this.$message({
            type: 'success',
            message: 'Page(s) has(have) been imported successfully.',
          });
          this.hide_pdf_preview_dialog();
          this.images_list = [];
          this.clear_content();
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        (reason) => {
          this.main_content_loading = false;
          this.$message({
            type: 'error',
            message: 'Error hapened while loading file.',
          });
        }
      );
    },
    clear_content() {
      this.selected_page = '';
      d3.select('#import_pdf_selected_svg').html('');
      this.svg = '';
      this.svg_target_width = '';
      this.svg_target_height = '';
    },
    load_pdf_file(file) {
      this.dialog_loading = true;
      let _this = this;
      this.clear_content();

      var formData = new FormData();
      this.selected_file = file;
      if (file.path) formData.append('path', file.path);
      else formData.append('file', file.raw);

      this.$http.post('/get_pdf_pages_thumbs', formData).then(
        (response) => {
          _this.handle_success(response.data);
        },
        (reason) => {
          this.dialog_loading = false;
          this.$message({
            type: 'error',
            message: 'Error hapened while loading file.',
          });
        }
      );
    },
    reset_changes() {
      this.selected_page.target_width = this.selected_page.actual_width;
      this.selected_page.target_height = this.selected_page.actual_height;
      this.svg_target_width = this.selected_page.actual_width / this.mm_to_px;
      this.svg_target_height = this.selected_page.actual_height / this.mm_to_px;
      this.selected_page.scale = 1;
      this.refresh_svg_boundary();
    },
    scratch_init() {
      let _this = this;
      this.clear_scratchpad();
      let view_box_arr = this.svg.attr('viewBox').split(' ');

      this.svg
        .append('g')
        .classed('scratchpad', true)
        .append('rect')
        .attr('width', view_box_arr[2])
        .attr('height', view_box_arr[3])
        .attr('cursor', 'crosshair')
        .attr('fill', 'rgba(240, 248, 255, 0.75)')
        .attr('stroke', 'lightblue')
        .attr('stroke-width', '3px');
    },
    scale_by_points() {
      let _this = this;
      this.scaling_points = [];
      this.scratch_init();

      _this.svg
        .select('.scratchpad')
        .append('line')
        .attr('stroke', 'red')
        .attr('stroke-wdith', '2px')
        .classed('scaling_line', true);

      _this.svg.select('.scratchpad').on('click', function() {
        var coords = d3.mouse(this);
        if (_this.scaling_points.length == 0)
          _this.svg
            .select('.scaling_line')
            .attr('x1', coords[0])
            .attr('y1', coords[1]);
        else if (_this.scaling_points.length == 1) {
          _this.coordinates_correction(coords);
        }
        _this.scaling_points.push(coords);
        if (_this.scaling_points.length == 2) {
          _this.confirm_scaling();
        }
      });

      d3.select('.scratchpad').on('mousemove', function() {
        if (_this.scaling_points.length == 1) {
          var coords = d3.mouse(this);
          _this.coordinates_correction(coords);
        }
      });
    },
    confirm_scaling() {
      this.scaling_points = [];
      d3.select('.scratchpad').on('click', null);
      d3.select('.scratchpad').on('mousemove', null);

      let scale_obj = this.svg.select('.scaling_line');
      let scaling_line = {
        x1: scale_obj.attr('x1'),
        y1: scale_obj.attr('y1'),
        x2: scale_obj.attr('x2'),
        y2: scale_obj.attr('y2'),
      };

      let current_length = scale_obj.node().getTotalLength();
      current_length = Math.round(current_length / this.mm_to_px);

      this.$prompt('Please enter distance in mm:', 'Distance', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputType: 'number',
        inputValue: current_length,
        roundButton: true,
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: 'New distance ' + value,
        });
        let line_px = value * this.mm_to_px;
        //let factor = this.get_line_factor(scaling_line, line_px);
        let factor = value / current_length;

        this.selected_page.target_width = this.selected_page.actual_width * factor;
        this.selected_page.target_height = this.selected_page.target_height * factor;
        this.selected_page.scale = factor;
        this.svg_target_width = this.selected_page.target_width / this.mm_to_px;
        this.svg_target_height = this.selected_page.target_height / this.mm_to_px;

        this.refresh_svg_boundary();
        this.clear_scratchpad();
      });
    },
    clear_scratchpad() {
      this.svg.selectAll('.scratch_drawing').remove();
      this.svg.selectAll('.scratchpad').remove();
    },
    // update svg boundary based on target specs
    refresh_svg_boundary() {
      let view_box_arr = this.svg.attr('viewBox').split(' ');
      let updated_view_box = `0 0 ${this.selected_page.target_width} ${this.selected_page.target_height}`;
      this.svg.attr('viewBox', updated_view_box);
    },
    coordinates_correction(coords) {
      let x1 = parseFloat(this.svg.select('.scaling_line').attr('x1'));
      let y1 = parseFloat(this.svg.select('.scaling_line').attr('y1'));

      let x2 = coords[0];
      let y2 = coords[1];

      let deltaX = Math.abs(coords[0] - x1);
      let deltaY = Math.abs(coords[1] - y1);

      if (deltaX > deltaY) {
        x2 = coords[0];
        y2 = y1;
      } else {
        x2 = x1;
        y2 = coords[1];
      }

      this.svg
        .select('.scaling_line')
        .attr('x2', x2)
        .attr('y2', y2);
    },
    get_line_factor(line, new_distance) {
      // Method used to calculate x or y factor
      // against new mm distance defined by the user
      let factor = 1;
      if (Math.round(line.x1) == Math.round(line.x2)) {
        let deltaY = line.y2 - line.y1;
        let detaYmm = Math.round(Math.abs(deltaY));
        factor = new_distance / detaYmm;
      } else {
        let deltaX = line.x2 - line.x1;
        let detaXmm = Math.round(Math.abs(deltaX));
        factor = new_distance / detaXmm;
      }
      return factor;
    },
    on_error(err, file, fileList) {
      this.dialog_loading = false;
      this.$message({
        type: 'error',
        message: 'Error happened while uploading the file, please try again!',
      });
    },
    handle_success(e) {
      this.dialog_loading = false;
      this.images_list = [];
      this.images_list = e.images_list;
      this.$message({
        type: 'success',
        message: 'Pages have been loaded successfully.',
      });
      let _this = this;
      setTimeout(function() {
        _this.set_inputs_to_default();
      }, 500);
    },
    load_svg_content(page) {
      this.clear_content();
      if (this.selected_file == null || this.selected_file == undefined) {
        this.$message({ type: 'warning', message: 'No file selected.' });
        return;
      }
      this.selected_page = page;
      let _this = this;
      this.main_content_loading = true;
      let formData = new FormData();
      if (this.selected_file.path)
        formData.append('path', this.selected_file.path);
      else formData.append('file', this.selected_file.raw);
      formData.append('page_num', page.page_num);
      formData.append('dpi', this.selected_resolution);
      formData.append('rotation', page.rotation);

      this.$http.post('/get_svg_by_page_num', formData).then(
        (response) => {
          d3.select('#import_pdf_selected_svg').html(response.body.svg_content);
          _this.svg = d3.select('#import_pdf_selected_svg svg');
          let main_svg_content = d3
            .select('#import_pdf_selected_svg svg')
            .node();

          //let specs = this.get_relative_boundaries(main_svg_content);
          if (
            page.actual_width == undefined ||
            page.actual_height == undefined
          ) {
            page.actual_width = d3
              .select('#import_pdf_selected_svg svg')
              .node()
              .getAttribute('viewBox')
              .split(' ')[2];
            page.actual_height = d3
              .select('#import_pdf_selected_svg svg')
              .node()
              .getAttribute('viewBox')
              .split(' ')[3];
          }
          if (
            page.target_width == undefined ||
            page.target_height == undefined
          ) {
            // in case target width & target height were not defined
            page.target_width = page.actual_width;
            page.target_height = page.actual_height;
          }
          
          _this.svg_target_width = page.target_width / _this.mm_to_px;
          _this.svg_target_height = page.target_height / _this.mm_to_px;

          d3.select('#import_pdf_selected_svg svg').attr('width', null);
          d3.select('#import_pdf_selected_svg svg').attr('height', null);

          _this.main_content_loading = false;
          _this.refresh_svg_boundary();
        },
        (reason) => {
          this.$message({
            type: 'error',
            message: 'Error happened while loading page content.',
          });
          _this.main_content_loading = false;
        }
      );
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll('#import_pdf_preview .el-input')
      ).forEach(function(e, i) {
        e.classList.add('browser-default');
        if (e.querySelector('input') != null)
          e.querySelector('input').className += ' browser-default';
      });
    },
    get_relative_boundaries(main_content) {
      let x1, y1, x2, y2, width, height, svgP, pt;

      pt = this.svg.createSVGPoint();
      pt.x = main_content.getBoundingClientRect().x;
      pt.y = main_content.getBoundingClientRect().y;

      svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      x1 = svgP.x;
      y1 = svgP.y;

      pt.x = main_content.getBoundingClientRect().right;
      pt.y = main_content.getBoundingClientRect().bottom;
      svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      x2 = svgP.x;
      y2 = svgP.y;
      width = x2 - x1;
      height = y2 - y1;

      return {
        width: width,
        height: height,
      };
    },
  },
};
</script>

<style>
#vertical_panel_div {
  max-height: 400px;
}

#panel_div {
  overflow-y: auto;
}

#panel_div > .active_page {
  border: 3px solid orange !important;
}

#panel_div > .el-card {
  /* width: 200px; */
  display: table-cell;
  cursor: pointer;
}

#panel_div div.label {
  color: #9c9c9c;
  white-space: nowrap;
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

#panel_div button {
  padding: 4px 7px !important;
}

#panel_div .hide_buttons {
  visibility: hidden;
}

#panel_div .el-button-group {
  width: 120px;
}

#vertical_panel_div {
  height: 100%;
}

#import_pdf_preview_dialog .el-dialog__body {
  height: 90%;
}

.selected_page {
  border: 1px solid orange;
}
</style>
