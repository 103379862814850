<template>
  <div id="pay-run-transactions">
    <el-date-picker
      v-model="month_picked"
      type="month"
      format="MM.yyyy"
      size="small"
      :clearable="false"
      placeholder="Please pick a month."
    />
    <el-button
      size="mini"
      type="primary"
      @click="pay_run_transaction_monthly(true)"
    >
      Search
    </el-button>
    <p />

    <el-collapse accordion>
      <el-collapse-item
        v-for="(pay_run, pay_run_index) in pay_runs_monthly"
        :key="pay_run.id"
        :name="pay_run.id"
      >
        <template slot="title">
          <table>
            <tr>
              <td><span class="dimgrey">Employees: </span><span>{{ pay_run.transactions.length }}</span></td>
              <td><span class="dimgrey">Pay Date: </span><span>{{ pay_run.transactions[0].employee_data.next_pay_day }}</span></td>
              <td><span class="dimgrey">Period: </span><span>{{ pay_run.pay_period.start_calculated }} - {{ pay_run.pay_period.end_calculated }}</span></td>
              <td>
                <span class="dimgrey">Period: </span><span>{{ pay_run.pay_frequency }}</span><br>
                <el-link
                  style="color: #409EFF"
                  @click="pay_transaction_summary_sheet(pay_run.pay_period_id)"
                >
                  IR Payday Filling File Upload {{ pay_run.pay_period_id }}
                </el-link>
              </td>
            </tr>
          </table>
        </template>
        <el-tabs type="border-card">
          <el-tab-pane label="Employees">
            <el-table
              ref="employee_table"
              :data="pay_run.transactions"
              style="width: 100%"
              stripe
            >
              <el-table-column
                v-slot="scope"
                type="expand"
                @expand-change="set_default_input();"
              >
                <div class="margin-left earning-details">
                  <table>
                    <th />
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <tr>
                      <td>
                        <span><b>Days paid</b></span>
                      </td>
                      <td>
                        <span>{{ scope.row.employee_data.working_hours_in_days }} days  | {{ scope.row.employee_data.working_hours }} hours</span>
                      </td>
                      <td><money :amount="scope.row.employee_data.pay_rate" /></td>
                      <td><money :amount="scope.row.employee_data.working_hours_pay_rate" /></td>
                    </tr>
                    <tr class="border-bottom-none">
                      <td><span><b>Earnings</b></span></td>
                    </tr>
                    <tr
                      v-for="(day, index) in scope.row.employee_data.public_holiday"
                      :key="index"
                    >
                      <td>
                        <span class="margin-left-10">Public holiday</span>
                        <span>{{ day.date_formatted }} {{ day.name }}</span>
                      </td>
                      <td>
                        <span>Hours {{ scope.row.employee_data.public_holiday[index].public_holiday_hours[0].hours }}</span>
                      </td>
                      <td>
                        <span>$ {{ scope.row.employee_data.public_holiday[index].public_holiday_hours[0].rate }}</span>
                      </td>
                      <td>
                        <span>
                          <money :amount="scope.row.employee_data.public_holiday[index].public_holiday_hours[0].total" />
                        </span>
                      </td>
                    </tr>
                    <tr v-if="scope.row.employee_data.public_holiday > 0 && scope.row.employee_data.public_holiday.length > 1">
                      <td />
                      <td />
                      <td />
                      <td>
                        <span>
                          <money :amount="scope.row.employee_data.public_holiday" />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="margin-left-10">Ordinary hours</span></td>
                      <td>
                        <span>Hours {{ scope.row.employee_data.working_hours }}</span>
                      </td>
                      <td>
                        <span>${{ scope.row.employee_data.pay_rate }}</span>
                      </td>
                      <td>
                        <span>
                          <money :amount="scope.row.employee_data.working_hours_pay_rate" />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="margin-bottom"><b>Expenses</b></span>
                        <br>
                        <span class="margin-left-10">Travel Food Allowance</span>
                      </td>
                      <td>
                        <br>
                        {{ scope.row.employee_data.travel_food_allowance.quantity }}
                      </td>
                      <td>
                        <br>
                        <span>$ {{ scope.row.employee_data.travel_food_allowance.rate }}</span>
                      </td>
                      <td>
                        <br>
                        <span>
                          <money :amount="scope.row.employee_data.travel_food_allowance.total" />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="margin-bottom"><b>Prior Period</b></span>
                        <br>
                        <span class="margin-left-10">Prior Period Gross Adjustments</span>
                      </td>
                      <td>
                        <br>
                        {{ scope.row.employee_data.prior_gross_adjustment.quantity }}
                      </td>
                      <td>
                        <br>
                        <span>$ {{ scope.row.employee_data.prior_gross_adjustment.rate }}</span>
                      </td>
                      <td>
                        <br>
                        <span>
                          <money :amount="scope.row.employee_data.prior_gross_adjustment.total" />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span><b>Holidays and leave</b></span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <leave-requests-user-transactions
                          :start="pay_run.pay_period.start_calculated"
                          :end="pay_run.pay_period.end_calculated"
                          :crm_user_id="scope.row.employee_data.id"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span><b>Superannuation</b></span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="margin-left-10">Employee contribution {{ scope.row.employee_data.kiwi_saver_contributions }}%</span></td>
                      <td />
                      <td />
                      <td />
                      <td><money :amount="scope.row.employee_data.superannuation_employee" /></td>
                    </tr>
                    <tr>
                      <td><span class="margin-left-10">Employer contribution {{ scope.row.employee_data.employer_contributions }}%</span></td>
                      <td />
                      <td />
                      <td />
                      <td><money :amount="scope.row.employee_data.superannuation" /></td>
                    </tr>
                    <tr>
                      <td><span class="margin-left-10">Employer superannuation contribution tax (ESCT) {{ scope.row.employee_data.superannuation_esct_rate_employer }}%</span></td>
                      <td />
                      <td />
                      <td />
                      <td><money :amount="scope.row.employee_data.superannuation_tax" /></td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td><b>Total Earning:</b> <money :amount="scope.row.employee_data.gross_payment_total" /></td>
                      <td><b>Taxes:</b> <money :amount="scope.row.employee_data.income_tax" /></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Payslips</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        Payslip email:
                        <el-input
                          v-model="scope.row.employee_data.payslip_email"
                          class="width240"
                          size="small"
                        />
                        <el-button
                          size="mini"
                          type="success"
                          @click="update_payslip_message(pay_run_index, scope.$index, scope.row.employee_data.id)"
                        >
                          Save
                        </el-button>
                        <el-button
                          size="mini"
                          type="info"
                          @click="email_payslip(pay_run_index, scope.$index, scope.row.employee_data.id)"
                        >
                          Resend
                        </el-button>
                        <el-button
                          size="mini"
                          type="primary"
                          @click="download_payslip(pay_run_index, scope.$index, scope.row.employee_data.id)"
                        >
                          Download
                        </el-button>
                        <br><br>
                        Payslip message:
                        <el-input
                          v-model="scope.row.employee_data.payslip_message"
                          type="textarea"
                          :autosize="{ minRows: 3}"
                          size="small"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </el-table-column>
              <el-table-column
                label="Name"
                property="name"
                width="200px"
              >
                <template #default="scope">
                  <el-link
                    class="color-link"
                    @click="edit_employee(scope.row.employee_data.id)"
                  >
                    {{ scope.row.employee_data.name }}
                  </el-link>
                  <br>
                  <b><money :amount="scope.row.employee_data.pay_rate" /></b>/hour
                  <span v-if="scope.row.employee_data.employment_pay_type == 'Hourly wage rate'">Wages</span>
                  <span v-else>Salary</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Leave"
                prop="leave"
              >
                <template #default="scope">
                  {{ scope.row.employee_data.leave_details.leave_period_sum }} hours
                  <br>
                  {{ (scope.row.employee_data.leave_details.leave_period_sum / 8.0).toFixed(2) }} days
                </template>
              </el-table-column>
              <el-table-column
                label="Expenses"
                prop="total_expenses"
              >
                <template #default="scope">
                  <b><money :amount="scope.row.employee_data.travel_food_allowance.total" /></b>
                </template>
              </el-table-column>
              <el-table-column
                label="Days"
                prop="working_hours_in_days"
              >
                <template #default="scope">
                  <b>{{ scope.row.employee_data.working_hours_in_days }}</b>
                  <span v-if="scope.row.employee_data.working_hours_in_days != scope.row.employee_data.working_hours_in_days_last_period">
                    <span
                      v-if="scope.row.employee_data.working_hours_in_days > scope.row.employee_data.working_hours_in_days_last_period"
                      class="triangle-up"
                    />
                    <span
                      v-else
                      class="triangle-down"
                    />
                    {{ scope.row.employee_data.working_hours_in_days_last_period }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="Hours"
                prop="working_hours"
              >
                <template #default="scope">
                  <b>{{ scope.row.employee_data.working_hours }}</b>
                  <span v-if="scope.row.employee_data.working_hours != scope.row.employee_data.working_hours_last_period">
                    <span
                      v-if="scope.row.employee_data.working_hours > scope.row.employee_data.working_hours_last_period"
                      class="triangle-up"
                    />
                    <span
                      v-else
                      class="triangle-down"
                    />
                    {{ scope.row.employee_data.working_hours_last_period }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="Take Home Pay"
                prop="take_home_pay"
              >
                <template #default="scope">
                  <b><money :amount="scope.row.employee_data.take_home_pay" /></b>
                </template>
              </el-table-column>
              <el-table-column
                label="Previous Pay"
                prop="last_period_take_home_pay"
              >
                <template #default="scope">
                  <money
                    class="lightgrey"
                    :amount="scope.row.employee_data.last_period_take_home_pay"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Payments">
            <el-table
              ref="payments_table"
              :data="pay_run.transactions"
              style="width: 100%"
              stripe
            >
              <el-table-column
                label="Recipient"
                property="name"
                width="200px"
              >
                <template #default="scope">
                  <el-link
                    style="color: #409EFF"
                    @click="edit_employee(scope.row.employee_data.id)"
                  >
                    {{ scope.row.employee_data.name }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="Bank Account"
                prop="bank_account"
              >
                <template #default="scope">
                  {{ scope.row.employee_data.bank_account }}
                </template>
              </el-table-column>
              <el-table-column
                label="Inland Revenue"
                prop="bank_account_inland_revenue"
              />
              <el-table-column
                label="PAYE"
                prop="total_deduction"
              >
                <template #default="scope">
                  {{ scope.row.employee_data.total_deduction }}
                </template>
              </el-table-column>
              <el-table-column
                label="Payment"
                prop="take_home_pay"
              >
                <template #default="scope">
                  <money :amount="scope.row.total_home_payment" />
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>

import {set_default_input} from '../../utilities/DefaultInput';
import {pay_run_transaction_monthly, pay_transaction_summary_sheet} from '../../api/pay_run_transactions';
import Money from '../shared/Money.vue';
import LeaveRequestsUserTransactions from './LeaveRequestsUserTransaction.vue';
import {change_payslip_message, change_prior_gross_adjustment} from '../../api/pay_runs';

export default {
  name: 'PayRunTransactions',
  components: {LeaveRequestsUserTransactions, Money},
  data() {
    return {
      pay_runs_monthly: [],
      month_picked: new Date(new Date().getFullYear(), new Date().getMonth()).toLocaleDateString('en-us', { year:'numeric', month:'short'})
    };
  },
  created() {
    this.set_default_input();
    //this.pay_run_transaction_monthly(false);
  },
  methods: {
    change_prior_gross_adjustment,
    update_payslip_message(pay_run_index, index, crm_user_id){
      const transaction = this.pay_runs_monthly[pay_run_index].transactions[index];
      change_payslip_message(this, crm_user_id, transaction.id, transaction.employee_data.payslip_email, transaction.employee_data.payslip_message).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
      });
    },
    pay_run_transaction_monthly(picker) {
      pay_run_transaction_monthly(this, this.month_picked, picker).then((response)=> {
        this.pay_runs_monthly = response.data;
        if(response.data[0] != undefined) {
          this.month_picked = response.data[0].month_picked;
        }
      });
    },
    pay_transaction_summary_sheet(pay_period_id){
      this.loading = true;
      pay_transaction_summary_sheet(this, pay_period_id).then((response) => {
        this.loading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let file_name = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        file_name = file_name.replaceAll('"','');
        link.href = url;
        link.setAttribute('download', file_name || 'inland_revenue_summaries.csv');
        document.body.appendChild(link);
        link.click();
      }).catch(()=>{
        this.loading = false;
      });
    },
    edit_employee(id) {
      window.open(`/crm_users/${id}/edit#/employment_details`, '_blank');
    },
    email_payslip(pay_run_index, index, crm_user_id){
      const employee_data = this.pay_runs_monthly[pay_run_index].transactions[index].employee_data;
      this.$http.post(`/api/crm_users/${crm_user_id}/pay_runs/pay_run_email_payslip`,
        { employee_data: [employee_data] }
      ).then( () => {
        this.$message({ type: 'success', message: 'Email to employee sent.' });
      });
    },
    download_payslip(pay_run_index, index, crm_user_id){
      const employee_data = this.pay_runs_monthly[pay_run_index].transactions[index].employee_data;
      this.$http.post(`/api/crm_users/${crm_user_id}/pay_runs/pay_run_download_payslip`,
        { employee_data:  [employee_data] },
        { responseType: 'blob' }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers.get('content-disposition').split('UTF-8\'\'')[1].trim());
        document.body.appendChild(link);
        link.click();
      });
    },
    set_default_input
  }
};

</script>
<style scoped>
th {font-size: 12px;font-weight: normal;color: grey;width: 25%;}
td, th {padding: 0px;}
.el-card { margin-bottom: 20px;}
.earning-details tr {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}
.earning-details td {
  padding: 10px 0px;
}
.earning-details td:first-child {
  padding-left: 10px;
}
.margin-left{ margin-left: 20px;}
.width240{ width: 240px;}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid green;
  display: inline-block;
  margin-left: 8px;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid red;
  display: inline-block;
  margin-left: 8px;
}
</style>
